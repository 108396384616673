import {
    Alert,
    Box,
    Button,
    FormControlLabel,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import UndoIcon from '@mui/icons-material/Undo';
import { H2Lighter, StripedTableRow, TableCellHead } from '../../../theming/customTheme';
import CenteredPaper from '../container/centeredPaper.component';
import CancelIcon from '@mui/icons-material/Cancel';
import WaitingScreenComponent from '../waiting-screen.component';
import UserTooltipComponent from '../tooltip/user-tooltip.component';
import DisplayAvatarComponent from '../infos/display-avatar.component';

/**
 * Composant permettant de générer rapidement et de manière générique
 * une page de gestion des données dans NeoCRA
 * @param {string} objectName Nom de l'objet qui sera traité
 * @param {string} title Titre de la page
 * @param {string} titleAdd Texte affiché dans le bouton d'ajout
 * @param {string} linkAdd Lien de redirection du bouton d'ajout
 * @param {object[]} dataContent Tableau des objets à afficher dans le tableau. L'id doit obligatoirement être nommé 'id' ou '<objectName>_id'. Les éléments qui ne seront pas affiché dans le tableau ne doivent pas être dans l'objet
 * @param {string} linkEdit String du lien vers la page d'update de l'objet.
 * @param {function} onChangeDisplayType [Paramètre optionnel] Fonction utilisé par le switch d'affichage en mode archive. Si non renseigné, ignore la partie d'archivage de la page (bouton, switch, etc.)
 * @param {boolean} displayType State boolean servant à l'affichage du mode archive si false.
 * @param {string} labelSwitchArchived [Paramètre optionnel] Label affiché à côté du switch de changement d'affichage des éléments affichés.
 * @param {function} handleRestore [Paramètres optionnel] Fonction appelé pour réstauré l'objet
 * @param {string} labelRestore [Paramètres optionnel] String à afficher dans le bouton de restauration
 * @param {boolean} showId [Paramètre optionnel] Booléen définissant l'affichage ou non de l'id dans le tableau, par défaut à false
 * @param {string[]} headers [Paramètre optionnel] Tableau de string listant les headers à afficher, si non-renseigné, ce sera les index du premier élément du tableau dataContent qui seront pris. Il est possible de renseigner un objet à la place d'un string contenant les attributs title et/ou filter et/ou size
 * @param {function} actionsAddedContent [Paramètre optionnel] Fonction retournant du contenu React/Html permettant l'ajout de contenu customisé dans la partie "actions" des lignes du tableau. La fonction doit prendre en paramètre un id qu'elle pourra utiliser au besoin.
 * @param {function} buttonsAddedContent [Paramètre optionnel] Fonction retournant du contenu React/Html permettant l'ajout de contenu customsé avant le bouton "créer". Récupère en paramètre le contenu de dataContent
 * @param {string} error [Paramètre optionnel] Chaine de caractère contenant l'erreur à afficher.
 * @param {string} error [Paramètre optionnel] Booléen permettant d'afficher un chargement si true.
 * @param {boolean} skipColorCheck [Paramètre optionnel] Booléen permettant d'éviter le comportement spécifique aux couleurs.
 * @param {boolean} withAvatar [Paramètre optionnel] Booléean permettant l'affichage des avatars d'utilisateur. Nécessite une propriété "_avatarDisplay" dans l'objet' content.
 */

const CrudPageComponent = (props) => {
    const showId = props.showId || false;
    const withAvatar = props.withAvatar || false;
    const showArchive = !!props.onChangeDisplayType;
    const skipColorCheck = props.skipColorCheck || false;

    return (
        <>
            <H2Lighter>{props.title}</H2Lighter>
            {!!props.error ? (
                <CenteredPaper size={props.size || null} withMargin={false}>
                    <CancelIcon sx={{ fontSize: 100 }} color="error" />
                    <Alert severity="error">{props.error}</Alert>
                </CenteredPaper>
            ) : (
                <>
                    {!!props.buttonsAddedContent && props.buttonsAddedContent(props.dataContent)}
                    {props.linkAdd && props.titleAdd && (
                        <Button
                            component={Link}
                            to={props.linkAdd}
                            variant="contained"
                            sx={{ mt: 1 }}
                        >
                            <AddIcon sx={{ mr: 1 }} /> {props.titleAdd}
                        </Button>
                    )}
                    {showArchive && (
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={() => {
                                        props.onChangeDisplayType();
                                    }}
                                />
                            }
                            label={props.labelSwitchArchived}
                            sx={{ ml: 1 }}
                        />
                    )}
                    {!Boolean(props.loading) ? (
                        <TableContainer
                            component={Paper}
                            elevation={4}
                            sx={{ mt: 1, textAlign: 'left' }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {!!props.headers
                                            ? props.headers.map((header, index) => {
                                                  return (
                                                      <TableCellHead
                                                          key={
                                                              props.objectName + '_header_' + index
                                                          }
                                                          width={header.size || ''}
                                                      >
                                                          {!!header.filter ? (
                                                              <TableSortLabel
                                                                  active={header.filter.filterState}
                                                                  direction={
                                                                      header.filter.filterState
                                                                          ? 'asc'
                                                                          : 'desc'
                                                                  }
                                                                  onClick={() =>
                                                                      header.filter.filterFunction()
                                                                  }
                                                              >
                                                                  {header.title}
                                                              </TableSortLabel>
                                                          ) : props.displayType ? (
                                                              header.title || header
                                                          ) : (
                                                              header.secondary ||
                                                              header.title ||
                                                              header
                                                          )}
                                                      </TableCellHead>
                                                  );
                                              })
                                            : !!props.dataContent &&
                                              props.dataContent.length > 0 &&
                                              Object.keys(props.dataContent[0]).map(
                                                  (header, index) => {
                                                      if (
                                                          header === 'id' ||
                                                          header === props.objectName + '_id'
                                                      ) {
                                                          if (showId) {
                                                              return (
                                                                  <TableCellHead
                                                                      key={
                                                                          props.objectName +
                                                                          '_header_' +
                                                                          index
                                                                      }
                                                                  >
                                                                      Id
                                                                  </TableCellHead>
                                                              );
                                                          }
                                                      } else if (withAvatar) {
                                                          return (
                                                              <TableCellHead
                                                                  key={'extra_key'}
                                                              ></TableCellHead>
                                                          );
                                                      } else {
                                                          return (
                                                              <TableCellHead
                                                                  key={
                                                                      props.objectName +
                                                                      '_header_' +
                                                                      index
                                                                  }
                                                              >
                                                                  {header.charAt(0).toUpperCase() +
                                                                      header.slice(1).toLowerCase()}
                                                              </TableCellHead>
                                                          );
                                                      }
                                                  },
                                              )}
                                        {(props.displayType
                                            ? !!props.linkEdit || !!props.actionsAddedContent
                                            : showArchive &&
                                              (!!props.handleRestore ||
                                                  !!props.actionsAddedContent)) === true && (
                                            <TableCellHead width="20%">Actions</TableCellHead>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!!props.dataContent ? (
                                        props.dataContent.length > 0 &&
                                        props.dataContent.map((datas, index) => {
                                            if (!!datas && Object.keys(datas).length > 0) {
                                                return (
                                                    <StripedTableRow
                                                        key={props.objectName + '_row_' + index}
                                                        className={
                                                            !props.displayType &&
                                                            showArchive &&
                                                            'isDelete'
                                                        }
                                                    >
                                                        {Object.keys(datas).map(
                                                            (data, indexData) => {
                                                                if (
                                                                    data === 'id' ||
                                                                    data ===
                                                                        '' +
                                                                            props.objectName +
                                                                            '_id'
                                                                ) {
                                                                    if (showId) {
                                                                        return (
                                                                            <TableCell
                                                                                key={
                                                                                    props.objectName +
                                                                                    '_sub_data_' +
                                                                                    indexData
                                                                                }
                                                                            >
                                                                                #{datas[data]}
                                                                            </TableCell>
                                                                        );
                                                                    }
                                                                } else {
                                                                    return (
                                                                        <TableCell
                                                                            key={
                                                                                props.objectName +
                                                                                '_sub_data_' +
                                                                                indexData
                                                                            }
                                                                        >
                                                                            {datas[data] !== null &&
                                                                                (data === 'color' ||
                                                                                data ===
                                                                                    'couleur' ? (
                                                                                    skipColorCheck ? (
                                                                                        datas[data]
                                                                                            .data ||
                                                                                        datas[data]
                                                                                    ) : (
                                                                                        <Box
                                                                                            sx={{
                                                                                                backgroundColor:
                                                                                                    datas[
                                                                                                        data
                                                                                                    ]
                                                                                                        .data ||
                                                                                                    datas[
                                                                                                        data
                                                                                                    ],
                                                                                                borderRadius:
                                                                                                    '5px',
                                                                                                p: 1,
                                                                                                fontWeight:
                                                                                                    'bold',
                                                                                                color: 'white',
                                                                                                textAlign:
                                                                                                    'center',
                                                                                            }}
                                                                                        >
                                                                                            {datas[
                                                                                                data
                                                                                            ]
                                                                                                .data ||
                                                                                                datas[
                                                                                                    data
                                                                                                ]}
                                                                                        </Box>
                                                                                    )
                                                                                ) : data ===
                                                                                      '_avatarDisplay' &&
                                                                                  withAvatar ? (
                                                                                    <DisplayAvatarComponent
                                                                                        user={
                                                                                            datas[
                                                                                                data
                                                                                            ]
                                                                                        }
                                                                                    />
                                                                                ) : !!datas[data]
                                                                                      .userTooltip ? (
                                                                                    <UserTooltipComponent
                                                                                        userEmail={
                                                                                            datas[
                                                                                                data
                                                                                            ].data
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            datas[
                                                                                                data
                                                                                            ].data
                                                                                        }
                                                                                    </UserTooltipComponent>
                                                                                ) : (
                                                                                    datas[data]
                                                                                        .data ||
                                                                                    datas[data]
                                                                                ))}
                                                                        </TableCell>
                                                                    );
                                                                }
                                                            },
                                                        )}
                                                        <TableCell width="5%">
                                                            {props.displayType ? (
                                                                props.linkEdit ? (
                                                                    <>
                                                                        {!!props.actionsAddedContent &&
                                                                            props.actionsAddedContent(
                                                                                datas,
                                                                            )}
                                                                        <Button
                                                                            variant="contained"
                                                                            size="small"
                                                                            component={Link}
                                                                            to={props.linkEdit}
                                                                            state={{
                                                                                objectId:
                                                                                    props.objectName ===
                                                                                    'users' // Cas particulier pour le formulaire utilisateurs
                                                                                        ? datas.email
                                                                                        : datas.id ||
                                                                                          datas[
                                                                                              '' +
                                                                                                  props.objectName +
                                                                                                  '_id'
                                                                                          ],
                                                                            }}
                                                                        >
                                                                            <EditIcon
                                                                                sx={{ mr: 1 }}
                                                                            />
                                                                            Modifier
                                                                        </Button>
                                                                    </>
                                                                ) : (
                                                                    !!props.actionsAddedContent &&
                                                                    props.actionsAddedContent(datas)
                                                                )
                                                            ) : (
                                                                showArchive &&
                                                                props.handleRestore && (
                                                                    <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        onClick={() =>
                                                                            props.handleRestore(
                                                                                datas.id ||
                                                                                    datas[
                                                                                        '' +
                                                                                            props.objectName +
                                                                                            '_id'
                                                                                    ],
                                                                            )
                                                                        }
                                                                    >
                                                                        <UndoIcon sx={{ mr: 1 }} />
                                                                        {props.labelRestore ||
                                                                            'Rétablir'}
                                                                    </Button>
                                                                )
                                                            )}
                                                        </TableCell>
                                                    </StripedTableRow>
                                                );
                                            }
                                        })
                                    ) : (
                                        <WaitingScreenComponent />
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <WaitingScreenComponent />
                    )}
                </>
            )}
        </>
    );
};

export default CrudPageComponent;
