import { Avatar, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import fichiersService from '../../../services/fichiers.service';
import WaitingScreenComponent from '../waiting-screen.component';

/**
 *  Composant affichant l'avatar d'un utilisateur ou ses initiales s'il n'en possède pas
 * @param {object} user Objet utilisateur comportant au moins le nom, le prénom, la photo et la fav_color.
 * @param {string} size "small", "medium" ou "large" (respectivement, 75px, 100px, 125px, par défaut : 50px)
 * @param {boolean} overlay [Paramètre optionnel] si true, affiche un tooltip avec le nom + prenom au hover
 */
const DisplayAvatarComponent = ({ user, pref, size, overlay, ...props }) => {
    const [photoProfileState, setPhotoProfileState] = useState(null);
    const withOverlay = overlay || false;

    let heightWidth = '50px';

    if (!!size) {
        switch (size) {
            case 'small':
                heightWidth = '75px';
                break;
            case 'medium':
                heightWidth = '100px';
                break;
            case 'large':
                heightWidth = '125px';
                break;
            default:
                heightWidth = size;
        }
    }

    useEffect(() => {
        // Si pref => télécharger image provenant de pref
        // Si pas pref => télécharger image provenant de user

        const filenamePhoto = !!pref ? pref.photo : user.photo;

        if (!!filenamePhoto && filenamePhoto !== -1) {
            fichiersService.getFichierFile(filenamePhoto).then((photo) => {
                if (!photo.error) {
                    setPhotoProfileState(photo);
                } else {
                    setPhotoProfileState(-1);
                }
            });
        } else {
            setPhotoProfileState(-1);
        }
    }, [pref, user]);

    return photoProfileState !== null ? (
        <Tooltip
            title={
                withOverlay && user.prenom && user.nom && user.nom.toUpperCase() + ' ' + user.prenom
            }
        >
            {photoProfileState === -1 ? (
                <Avatar
                    sx={{
                        width: heightWidth,
                        height: heightWidth,
                        mt: 1,
                        backgroundColor: user.fav_color || 'primary.main',
                    }}
                    {...props}
                >
                    {user.nom.charAt(0).toUpperCase()}
                    {user.prenom.charAt(0).toUpperCase()}
                </Avatar>
            ) : (
                <Avatar
                    src={photoProfileState}
                    sx={{ width: heightWidth, height: heightWidth, mt: 1 }}
                    {...props}
                />
            )}
        </Tooltip>
    ) : (
        <WaitingScreenComponent />
    );
};

export default DisplayAvatarComponent;
