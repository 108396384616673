import { Grid, SvgIcon, Typography } from '@mui/material';

// const type = ['CP', 'CE', 'RTT', 'F', 'M'];
// const typeNom = ['CP', 'CE', 'RTT', 'Férié', 'Maladie'];
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LockResetIcon from '@mui/icons-material/LockReset';
import CloseIcon from '@mui/icons-material/Close';

export const displayCongeStatus = (conge) => {
    if (!!conge.est_evalue) {
        return (
            (conge.est_evalue ? 'Évalué' : 'En attente') +
            ' - ' +
            (conge.est_valide ? 'Validé' : 'Refusé')
        );
    }
    return 'En attente';
};

export const getTypeTrigramme = (conge) => {
    return conge.type_conge.trigramme;
};

export const getTypeNom = (conge) => {
    return conge.type_conge.accronyme;
};

// Récupère la couleur à afficher en fonction du type de l'activité
// Si activité => retourne le code hexa du projet
// Si congés => retourne le code hexa en fonction de l'état d'avancement du congé
export const activityColor = (activity) => {
    if (!!activity.jour_conges_id) {
        // Congés
        if (!!activity.est_evalue) {
            if (!!activity.est_valide) {
                if (!!activity.demande_annulation) {
                    // Demande Annulation
                    return 'warning.main';
                } else {
                    // Validé
                    return 'success.main';
                }
            } else {
                // Refusé
                return 'error.main';
            }
        } else {
            // "En attente"
            return 'originalPrimary.main';
        }
    }

    return activity.projet.couleur;
};

export const displayStatusIcon = (activity, withColor = false, withTitle = false) => {
    if (
        activity.new_conges_id ||
        (activity.jour_conges_id && activity.type_conge.type_conge_id !== 4)
    ) {
        // Congés
        if (!!activity.est_evalue) {
            if (!!activity.est_valide) {
                if (!!activity.demande_annulation) {
                    // Demande Annulation
                    return (
                        <Grid container spacing={1}>
                            {withTitle && (
                                <Grid item>
                                    <Typography>En attente d'annulation</Typography>
                                </Grid>
                            )}
                            <Grid item>
                                <LockResetIcon
                                    sx={{
                                        color: withColor ? 'warning.main' : 'secondary.light',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    );
                } else {
                    // Validé
                    return (
                        <Grid container spacing={1}>
                            {withTitle && (
                                <Grid item>
                                    <Typography>Validé</Typography>
                                </Grid>
                            )}
                            <Grid item>
                                <CheckIcon
                                    sx={{
                                        color: withColor ? 'success.main' : 'secondary.light',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    );
                }
            } else {
                return (
                    <Grid container spacing={1}>
                        {withTitle && (
                            <Grid item>
                                <Typography>Refusé</Typography>
                            </Grid>
                        )}
                        <Grid item>
                            <CloseIcon
                                sx={{ color: withColor ? 'error.main' : 'secondary.light' }}
                            />
                        </Grid>
                    </Grid>
                );
            }
        } else {
            // "En attente"
            return (
                <Grid container spacing={1}>
                    {withTitle && (
                        <Grid item>
                            <Typography>En attente de validation</Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <AccessTimeIcon
                            sx={{ color: withColor ? 'primary.main' : 'secondary.light' }}
                        />
                    </Grid>
                </Grid>
            );
        }
    } else if (activity.activity_id) {
        return (
            <SvgIcon sx={{ color: 'secondary.light', width: '80%' }}>
                <path d={activity.lieu_activity.logo} />
            </SvgIcon>
        );
    }
    return '';
};

/**
 * Permet de définir dynamiquement les class des jours dans le mini-calendrier
 * @param  {[type]}  jour                     Jour de l'élément jour du calendrier
 * @param  {[type]}  month                    Correspond au this.month du composant
 * @param  {Boolean} isSelected               isSelected
 * @return {[String]}             Chaine de caractère comportant toute les classes nécéssaires de l'élément
 */
export const getClassNameMiniCalendar = (jour, month, isSelected) => {
    let retour = 'square ';

    if (isSelected) {
        retour += 'selected ';
    }
    if (jour.date.getMonth() !== month) {
        retour += 'otherMonth ';
    }
    if (jour.ferie != null) {
        retour += 'ferie';
    }

    return retour;
};
