const graviteeConfig = {
    development: {
        DOMAIN: 'neodt-nprod',
        AUTH_URL: 'https://preprod-auth.neodt.fr',
        GATEWAY_URL: 'https://preprod-gateway.neodt.fr',
        CLIENT_ID: '1ce88854-6a79-4ff8-a888-546a79cff812',
        REDIRECT_URI: `https://${window.location.hostname}:3000`,
        BASIC_TOKEN:
            'MWNlODg4NTQtNmE3OS00ZmY4LWE4ODgtNTQ2YTc5Y2ZmODEyOmRITnhhOHRJY1Y1RXJnSzRTR2duSnIyZkE3a0NFMDhGYXIzeTJpcXNhNk0=',
        SCOPE: 'openid full_profile',
    },
    recette: {
        DOMAIN: 'neodt-preprod',
        AUTH_URL: 'https://preprod-auth.neodt.fr',
        GATEWAY_URL: 'https://preprod-gateway.neodt.fr',
        CLIENT_ID: '9e9f6f27-1789-4171-9f6f-27178961711a',
        REDIRECT_URI: `https://${window.location.hostname}`,
        BASIC_TOKEN:
            'OWU5ZjZmMjctMTc4OS00MTcxLTlmNmYtMjcxNzg5NjE3MTFhOmRNeUk5RTV1SVJ5VmpYLVhDdWwzVnFaM0U4SWJsLWZGYnhEYzZ3a0x0THc=',
        SCOPE: 'openid full_profile',
    },
    production: {
        DOMAIN: 'neodt-prod',
        AUTH_URL: 'https://auth.neodt.fr',
        GATEWAY_URL: 'https://gateway.neodt.fr',
        CLIENT_ID: '3197b3c4-ebd1-47d1-97b3-c4ebd1c7d194',
        REDIRECT_URI: `https://neocra.neodt.fr`,
        BASIC_TOKEN:
            'MzE5N2IzYzQtZWJkMS00N2QxLTk3YjMtYzRlYmQxYzdkMTk0OlVCY0ZES3Q4R29jRnFnN3BOSmJ1eVUxb3pnRFpZVzMwaEdwbGZoNjgzM0E=',
        SCOPE: 'openid full_profile',
    },
};

export default graviteeConfig;
