import { Alert, List, ListItemButton, ListItemText, Paper } from '@mui/material';
import { H2Lighter } from '../../../theming/customTheme';
import CenteredPaper from '../../assets/container/centeredPaper.component';
import CreateContactComponent from './create-contact.component';

const CreateAddContactComponent = (props) => {
    let availableContacts = props.availableContacts || [];
    availableContacts = availableContacts.map((contact) => {
        return {
            ...contact,
            isChecked: props.contacts.some((el) => el.contact_id === contact.contact_id),
        };
    });

    return (
        <CenteredPaper size="extra-large" withMargin={false} withAlign={false} elevation={0}>
            <H2Lighter>Gestion des contacts</H2Lighter>
            <Paper sx={{ p: 1, mt: 2 }} elevation={2}>
                <h3> Contacts disponibles</h3>
                {availableContacts.length > 0 ? (
                    <List style={{ maxHeight: '100%', overflow: 'auto' }}>
                        {availableContacts.map((contact, index) => {
                            return (
                                <ListItemButton
                                    key={contact.contact_id}
                                    onClick={() =>
                                        !props.disabled && props.handleCheckContact(index, contact)
                                    }
                                >
                                    <ListItemText
                                        primary={contact.nom + ' ' + contact.prenom}
                                        secondary={
                                            'email: ' +
                                            contact.email +
                                            ' - tel: ' +
                                            contact.telephone
                                        }
                                    />
                                </ListItemButton>
                            );
                        })}
                    </List>
                ) : (
                    <Alert severity="info">Aucun contact disponible pour ce client.</Alert>
                )}
            </Paper>
            {!props.disabled && (
                <Paper sx={{ p: 1, mt: 2 }} elevation={2}>
                    <h3>Créer un nouveau contact</h3>
                    <CreateContactComponent client={props.client} />
                </Paper>
            )}
        </CenteredPaper>
    );
};

export default CreateAddContactComponent;
