import axiosConfig from './axiosConfig';

const API_URL = '/authorization/reactions/';

class ReactionsService {
    getAll() {
        console.trace(axiosConfig);
        return axiosConfig
            .get(API_URL + 'all')
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
    react(objectReact) {
        return axiosConfig
            .post(API_URL + 'react', objectReact)
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }
}

export default new ReactionsService();
