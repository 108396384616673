import axiosConfig from './axiosConfig';

const API_URL = '/authorization/new_conges/';

/**
 * Classe service de conge, regroupe les appels API via Axios.
 */
class CongeService {
    getNextConges(nbConges) {
        return axiosConfig
            .get(`${API_URL}next/${nbConges}`)
            .then((res) => res.data)
            .catch((err) => err.message);
    }

    getNbJour(dateDebut, dateFin, periodeDebut, periodeFin) {
        return axiosConfig
            .get(`${API_URL}countCongesDays/`, {
                params: { dateDebut, dateFin, periodeDebut, periodeFin },
            })
            .then((res) => res.data)
            .catch((err) => ({ error: err.message }));
    }

    insertConge(objectConges) {
        return axiosConfig
            .post(`${API_URL}`, objectConges)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    updateConge(objectConges) {
        return axiosConfig
            .put(`${API_URL}`, objectConges)
            .then((response) => response.data)
            .catch((err) => ({ error: err.message }));
    }

    askForCancel(idConges) {
        return axiosConfig
            .delete(`${API_URL}/${idConges}`)
            .then((response) => response.data)
            .catch((err) => ({ error: err.message }));
    }

    getCongesAskedForCancel() {
        return axiosConfig
            .get(`${API_URL}/askedForCancel`)
            .then((response) => response.data)
            .catch((err) => ({ error: err.message }));
    }

    getCongeByJourConge(idJour) {
        return axiosConfig
            .get(`${API_URL}byJourCongesId/${idJour}`)
            .then((response) => response.data)
            .catch((err) => ({ error: err.message }));
    }

    /**
     * Retourne les infos capital cumulé et en cours
     * @returns Array
     */
    getPersonalInfos(dateRef = null, userId = null) {
        return axiosConfig
            .get(`${API_URL}personal`, { params: dateRef && userId && { dateRef, userId } })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    /**
     * Retourne tous les congés en attente de validation
     * @returns Array
     */
    getAllWaitingConges() {
        return axiosConfig
            .get(`${API_URL}waiting`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }
    /**
     * Retourne tous les congés déjà évalué
     * @returns Array
     */
    getEvaluatedConges() {
        return axiosConfig
            .get(`${API_URL}allEvaluated`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: `${error.message}`,
            }));
    }

    getEmployeeMonthConges(monthNumber, yearNumber, userId = null) {
        return axiosConfig
            .get(`${API_URL}month`, {
                params: { monthNumber, yearNumber, utilisateursId: userId },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    /**
     * Retourne tous les cp,gés du mois via ID en params de manager
     * @param {Number} monthNumber - numéro du mois à visualiser
     * @param {Number} yearNumber - numéro de l'année à visualiser
     * @param {Number} managerEmail - id utilisateur du manager
     */
    getCongesByManager(monthNumber, yearNumber, managerEmail) {
        return axiosConfig
            .get(API_URL + 'manager', {
                params: { monthNumber, yearNumber, managerEmail },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    /**
     * Retourne les différents types de congés en base
     * @returns Array
     */
    getCongeTypes() {
        return axiosConfig
            .get(`${API_URL}types`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    evaluateConge(conge_id, est_valide = false) {
        return axiosConfig
            .post(`${API_URL}evaluate/${conge_id}`, {
                est_valide,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }
    evaluateCancelConge(conge_id, est_valide = false) {
        return axiosConfig
            .post(`${API_URL}evaluateCancel/${conge_id}`, {
                est_valide,
            })
            .then((response) => response.data)
            .catch((error) => ({ error: `${error.message}` }));
    }
    restoreEvaluation(conge_id) {
        return axiosConfig
            .put(`${API_URL}restoreEvaluation/${conge_id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }
    countAllCategoryConges() {
        return axiosConfig
            .get(`${API_URL}countAllCategoryConges`)
            .then((response) => response.data)
            .catch((error) => ({ error: `${error.message}` }));
    }

    getCanceledConges() {
        return axiosConfig
            .get(`${API_URL}allCanceled`)
            .then((response) => response.data)
            .catch((error) => ({ error: `${error.message}` }));
    }
}

export default new CongeService();
