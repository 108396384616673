import axiosConfig from './axiosConfig';

const API_URL = '/authorization/projet/';

class ProjetService {
    getAllProjets() {
        return axiosConfig
            .get(API_URL + 'all')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    getAvailableProjetClient() {
        return axiosConfig
            .get(API_URL + 'available')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }

    getAllProjetsByClientId(clientId) {
        return axiosConfig
            .get(API_URL + 'all/' + clientId)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }
    /**
     * Utilise le token de connexion pour retourner les projets liés
     * à l'utilisateur connecté
     */
    getProjetByToken() {
        return axiosConfig
            .get(API_URL + 'by-token')
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }
    // retourne le type activité par project id
    getActivityType(project_id) {
        return axiosConfig
            .get(`${API_URL}${project_id}/activity-type`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }
    // retourne le type activité par project id
    getCriticiteMatrice(project_id) {
        return axiosConfig
            .get(`${API_URL}${project_id}/criticity-ticket`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }
    // retourne un projet via id projet
    getProjet(project_id) {
        return axiosConfig
            .get(`${API_URL}/${project_id}`)
            .then((response) => response.data)
            .catch((error) => ({ error: `${error.message}` }));
    }

    getProjetWithFirstDate(project_id) {
        return axiosConfig
            .get(API_URL + 'withFirstDate/' + project_id)
            .then((res) => res.data)
            .catch((err) => ({
                error: err.message,
            }));
    }
    // retourne le contrat support via id projet
    getContratSupport(project_id) {
        return axiosConfig
            .get(API_URL + 'contrat-support/' + project_id)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return {
                    error: `${error.message}`,
                };
            });
    }
    /**
     *
     * @param {Number} client - id du client
     * @param {Number} region - id de la région
     * @param {Number} statusSelect - id du status
     * @param {String} code_projet - Code projet (doit être unique)
     * @param {String} nom - Nom de projet
     * @param {Date} date_commande - Date de commande
     * @param {Boolean} estPrepaye - Vrai si projet est prépayé
     * @param {String} commentaire - Commentaire du projet
     * @param {Number} typeActivity - id du type d'activité
     * @param {Number} typeFacturation - id du type de facturation
     * @param {Number} referent - id du référent (utilisateur)
     */
    createProjet(
        client,
        region,
        statusSelect,
        code_projet,
        nom,
        date_commande,
        estPrepaye,
        commentaire,
        typeActivity,
        typeFacturation,
        referent,
        trigramme,
        couleur,
        devises,
    ) {
        estPrepaye = estPrepaye ? 1 : 0;
        return axiosConfig
            .post(API_URL + 'create', {
                client,
                region,
                statusSelect,
                code_projet,
                nom,
                date_commande,
                estPrepaye,
                commentaire,
                typeActivity,
                typeFacturation,
                referent,
                trigramme,
                couleur,
                devises,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    /**
     *
     * @param {Number} client - id du client
     * @param {Number} region - id de la région
     * @param {Number} statusSelect - id du status
     * @param {String} code_projet - Code projet (doit être unique)
     * @param {String} nom - Nom de projet
     * @param {Date} date_commande - Date de commande
     * @param {Boolean} estPrepaye - Vrai si projet est prépayé
     * @param {String} commentaire - Commentaire du projet
     * @param {Number} typeActivity - id du type d'activité
     * @param {Number} typeFacturation - id du type de facturation
     * @param {Number} referent - id du référent (utilisateur)
     */
    updateProjet(
        project_id,
        client,
        region,
        statusSelect,
        code_projet,
        nom,
        referent,
        date_commande,
        estPrepaye,
        commentaire,
        typeActivity,
        typeFacturation,
        couleur,
        trigramme,
        devises,
    ) {
        estPrepaye = estPrepaye ? 1 : 0;
        return axiosConfig
            .put(API_URL + project_id, {
                client,
                region,
                statusSelect,
                code_projet,
                nom,
                referent,
                date_commande,
                estPrepaye,
                commentaire,
                typeActivity,
                typeFacturation,
                couleur,
                trigramme,
                devises,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    createProjetSupport(
        client,
        region,
        statusSelect,
        code_projet,
        nom,
        date_commande,
        estPrepaye,
        commentaire,
        typeActivity,
        typeFacturation,
        referent,
        trigramme,
        couleur,
        devises,
        checkedCriticites,
        contacts,
        gouvernance,
    ) {
        estPrepaye = estPrepaye ? 1 : 0;
        return axiosConfig
            .post(API_URL + 'create/support', {
                client,
                region,
                statusSelect,
                code_projet,
                nom,
                date_commande,
                estPrepaye,
                commentaire,
                typeActivity,
                typeFacturation,
                referent,
                trigramme,
                couleur,
                devises,
                checkedCriticites,
                contacts,
                gouvernance,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }

    updateProjetSupport(
        project_id,
        client,
        region,
        statusSelect,
        code_projet,
        nom,
        referent,
        date_commande,
        estPrepaye,
        commentaire,
        typeActivity,
        typeFacturation,
        couleur,
        trigramme,
        devises,
        checkedCriticites,
        contacts,
        gouvernance,
    ) {
        estPrepaye = estPrepaye ? 1 : 0;
        return axiosConfig
            .put(API_URL + 'contrat-support/' + project_id, {
                project_id,
                client,
                region,
                statusSelect,
                code_projet,
                nom,
                referent,
                date_commande,
                estPrepaye,
                commentaire,
                typeActivity,
                typeFacturation,
                couleur,
                trigramme,
                devises,
                checkedCriticites,
                contacts,
                gouvernance,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return { error: `${error.message}` };
            });
    }
}

export default new ProjetService();
