// redux
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { manualLogin } from '../../redux/actions/authAction';
import SimpleModalComponent from '../assets/modal/simple-modal.component';
import PackageJSON from '../../../package.json';
import { Navigate } from 'react-router';
import graviteeConfig from './gravitee.config';
import { setPref } from '../../redux/actions/prefAction';
import { GraviteeConnexionComponent } from 'react-gravitee-connexion';

const LoginComponent = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const [openModalState, setOpenModalState] = useState(false);
    const [moreThanOneUserState, setMoreThanOneUserState] = useState([]);
    const [resultState, setResultState] = useState(null);

    const handleLogin = (mail) => {
        setLoadingState(true);
        props
            .manualLogin(mail)
            .then((user) => {
                setLoadingState(false);
                props.setLogin(true);
                // A mettre dans une logique reducer ?
                props.setPrefUser({
                    ...props.pref,
                    fav_color: user.fav_color,
                    dark_mode: user.dark_mode,
                    photo: user.photo,
                });
            })
            .catch((err) => {
                props.setLogin(false);
                toast.error(err);
                setOpenModalState(false);
                setLoadingState(false);
            });
    };

    useEffect(() => {
        if (resultState) {
            setLoadingState(true);
            if (resultState.ldapUser.mail.length > 1) {
                setMoreThanOneUserState(resultState.ldapUser.mail);
                setOpenModalState(true);
                setLoadingState(false);
            } else {
                handleLogin(resultState.ldapUser.mail[0]);
                setLoadingState(false);
            }
        }
    }, [resultState]);

    const Msg = () => (
        <div style={{ textAlign: 'center' }}>
            <span>
                Bienvenue sur NeoCRA {PackageJSON.etat} {PackageJSON.version}
            </span>{' '}
            <br />
            <span>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                        'https://gitlab.com/neodt/neocra-frontend/-/tags/' +
                        PackageJSON.etat.toLowerCase() +
                        '-' +
                        PackageJSON.version
                    }
                >
                    Voir les nouveautés
                </a>
            </span>
        </div>
    );

    if (props.isLoggedIn) {
        toast.dark(Msg, {
            autoClose: 4000,
            hideProgressBar: true,
            position: 'bottom-center',
        });
        return <Navigate to="/home" />;
    }

    return (
        <>
            <SimpleModalComponent
                customSx={{ maxWidth: { xs: '90%', md: '70%' } }}
                isOpen={openModalState}
                handleCloseModal={() => setOpenModalState(false)}
            >
                <List>
                    <ListItemText
                        primary="Quel email choisir pour se connecter ?"
                        primaryTypographyProps={{
                            fontSize: 20,
                            fontWeight: 'medium',
                            letterSpacing: 0,
                        }}
                        sx={{
                            mb: 1,
                        }}
                    />
                    {moreThanOneUserState.map((mail, index) => (
                        <ListItem disablePadding key={'list_emails_' + index}>
                            <ListItemButton
                                onClick={() => handleLogin(mail)}
                                disabled={loadingState}
                            >
                                <ListItemIcon>
                                    <AccountCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={mail} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </SimpleModalComponent>

            <GraviteeConnexionComponent
                paramObject={{ graviteeConfig: graviteeConfig }}
                setResult={setResultState}
                ENV={process.env.REACT_APP_ENV || 'development'}
                loading={loadingState}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.authReducer.isLoggedIn,
        message: state.messageReducer.message,
        pref: state.prefReducer,
    };
};

const mapDispatchToProps = (dispatch) => ({
    setPrefUser: (prefObject) => dispatch(setPref(prefObject)),
    manualLogin: (mail) => dispatch(manualLogin(mail)),
});

export const ConnectedLoginComponent = connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
