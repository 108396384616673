import { useState } from 'react';
import contactService from '../../../services/contact.service';
import PinComponent from '../../assets/field/pin.component';
import SimpleModalComponent from '../../assets/modal/simple-modal.component';
import CreateAddContactComponent from './createAddContact.component';
import AddIcon from '@mui/icons-material/Add';

const ContactModuleComponent = (props) => {
    const [availableContactsState, setAvailableContactsState] = useState([]);
    const [openModalState, setOpenModalState] = useState(false);

    const handleFetchContacts = () => {
        props.client &&
            contactService.getContactsByClient(props.client).then((res) => {
                if (!res.error) {
                    setAvailableContactsState(res);
                }
            });
    };
    const handleOpenModal = () => {
        handleFetchContacts();
        setOpenModalState(true);
    };
    const handleCloseModal = () => {
        setOpenModalState(false);
    };

    return (
        <div>
            <div key={openModalState}>
                <SimpleModalComponent
                    content={
                        <CreateAddContactComponent
                            handleCheckContact={props.handleCheckContact}
                            client={props.client}
                            contacts={props.contacts}
                            availableContacts={availableContactsState}
                            disabled={props.disabled}
                        />
                    }
                    modalRoot={props.modalRoot}
                    isOpen={openModalState}
                    handleCloseModal={handleCloseModal}
                />
            </div>

            <h4>Gestion du/des contact(s)</h4>
            <div className="pins-container">
                {props.contacts &&
                    props.contacts.length > 0 &&
                    props.contacts.map((contact) => {
                        return (
                            <PinComponent
                                content={contact}
                                key={contact.nom}
                                handlePinClick={handleOpenModal}
                                handleRemoveContact={props.handleRemoveContact}
                                disabled={props.disabled}
                            />
                        );
                    })}
                {!props.disabled &&
                    (props.client ? (
                        <span onClick={handleOpenModal}>
                            <AddIcon color="primary" />
                            Ajouter contact
                        </span>
                    ) : (
                        <span className="disabled-content">
                            Sélectionner un client avant de pouvoir ajouter un contact.
                        </span>
                    ))}
            </div>
        </div>
    );
};

export default ContactModuleComponent;
