import { Box, Grid } from '@mui/material';
import PortalButtonComponent from '../../assets/portal-button.component';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const ManagerPortalCongesComponent = (props) => {
    return (
        <Box sx={{ p: 1 }}>
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 2, width: '100%' }}>
                <PortalButtonComponent
                    title="Évaluer les congés"
                    icon={BeachAccessIcon}
                    color="#789456"
                    link="manager/evaluate-conge"
                    role={props.role}
                />
                <PortalButtonComponent
                    title="Gérer les périodes de soldes"
                    icon={CalendarTodayIcon}
                    color="#123456"
                    link="manager/soldes"
                    role={props.role}
                />
            </Grid>
        </Box>
    );
};

export default ManagerPortalCongesComponent;
