import { useEffect, useState } from 'react';
import logoNeodt from '../../images/NeoDT_fndBlanc_SsTxt_30pp.png';
import logoNeo4t from '../../images/logo-neo4t.png';
import MenuComponent from '../assets/menu/menu.component';
import './panleft.style.scss';
import { connect } from 'react-redux';
import { toggleMenu } from '../../redux/actions/scopeAction';
import { Link } from 'react-router-dom';
import PackageJSON from '../../../package.json';

import { Divider, List, Grid, ListItem, ListItemText, ListItemButton } from '@mui/material';
import { DrawerPanLeft } from '../../theming/customTheme';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import WeatherComponent from '../assets/field/weather.component';

// TODO : Suppression des librairies devenues inutiles, suppression des warnings de build, écriture du changelog, mise en préprod

const PanleftComponent = (props) => {
    const user = JSON.parse(localStorage.getItem('user')) || '';

    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    useEffect(() => {
        const handler = (e) => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener('beforeinstallprompt', handler);

        return () => window.removeEventListener('transitionend', handler);
    }, []);

    const installClick = (evt) => {
        evt.preventDefault();
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
    };

    return (
        <DrawerPanLeft anchor="left" {...props.allProps} PaperProps={{ elevation: 0 }}>
            <List>
                <Link
                    to="/"
                    className="brand-container flex-container row-container aligned-container"
                    style={{ textDecoration: 'none' }}
                >
                    <Grid container>
                        <Grid item xs={6} sx={{ p: 2 }}>
                            {user.entity_name === 'Neo4T' ? (
                                <img src={logoNeo4t} alt="Double pacman de neo4T" width="100%" />
                            ) : (
                                <img src={logoNeodt} alt="Double pacman de neoDT" width="100%" />
                            )}
                        </Grid>
                        <Grid item style={{ color: 'white', textAlign: 'center' }}>
                            <h1>NeoCRA</h1>
                            <p>{user.entity_name}</p>
                        </Grid>
                    </Grid>
                </Link>
                <WeatherComponent />
                <Divider />
                {props.isLoggedIn && <MenuComponent />}
            </List>
            <List style={{ marginTop: `auto` }}>
                {supportsPWA && (
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item>
                            <ListItem>
                                <ListItemButton onClick={installClick} sx={{ textAlign: 'right' }}>
                                    Installer <InstallDesktopIcon sx={{ ml: 1 }} />
                                </ListItemButton>
                            </ListItem>
                        </Grid>
                    </Grid>
                )}
                <ListItem>
                    <ListItemText style={{ textAlign: 'center' }}>
                        © 2023 NeoDT. Tous droits réservés. <br />
                        <a
                            href={
                                'https://gitlab.com/neodt/neocra-frontend/-/tags/' +
                                PackageJSON.etat.toLowerCase() +
                                '-' +
                                PackageJSON.version
                            }
                        >
                            {PackageJSON.etat} {PackageJSON.version}
                        </a>
                    </ListItemText>
                </ListItem>
            </List>
        </DrawerPanLeft>
    );
};

// permet à notre composant de changer le state global pour le menu
const mapDispatchToProps = (dispatch) => ({
    doToggle: () => {
        dispatch(toggleMenu());
    },
});

const mapStateToProps = (state) => {
    return {
        toggleMenu: state.scopeReducer.toggleMenu,
        isLoggedIn: state.authReducer.isLoggedIn,
    };
};

export const ConnectedPanleftComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PanleftComponent);
